<script setup lang="ts">
import { useBrandsInfoStore } from '@/store/brandsInfo';
import { useBrandsStore } from '~/store/brands';
import { usePopupsStore } from '~/store/popups';

const { $api } = useNuxtApp();
const route = useRoute();
const brandInfoStore = useBrandsInfoStore();

const { setGamas, setSelectedBrand } = useBrandsStore();

const metaDatos = useMetadatos();
const popupsStore = usePopupsStore();

const splideRef = ref(null);

const options = {
  type   : 'loop',
  perPage: 3,
  // fixedWidth : '150px',
  // gap        : '26px',
  breakpoints: {
    992:{
      arrows: true,        
      fixedWidth : '220px'

    },
    1200: {
        arrows: true,
        fixedWidth : '400px',
    }
  },
  focus  : 'center',
  pagination: false,
  isNavigation: true,
  arrows: false,
  arrowPath: 'M22.2484 0.781051L39.219 17.7516C40.2604 18.793 40.2603 20.4815 39.219 21.5229L22.2484 38.4934C21.207 39.5348 19.5185 39.5348 18.4771 38.4934C17.4358 37.452 17.4358 35.7636 18.4772 34.7222L30.8954 22.3039L-1.94987e-06 22.3039L-1.48361e-06 16.9706L30.8954 16.9706L18.4772 4.55229C17.4358 3.51089 17.4358 1.82245 18.4772 0.78105C19.5186 -0.260348 21.207 -0.260348 22.2484 0.781051Z'
};


//Módulo Descubre todas nuestras marcas

const activeBrands= ref(brandInfoStore.getActiveBrandsOrdered());

const activeBrandsFilter = computed(()=> {
  return activeBrands.value.filter(elem => elem.slug != 'marcos-cycles');
});
const isMarcosCyclesActive = computed(()=> {
  return activeBrands.value.find(elem => elem.slug == 'marcos-cycles');
});

const brandDiscoverSelected= reactive({
  name: '',
  slug: ''
});

const brandAllBrandsDiscoverSelected = reactive({
  name: '',
  slug: ''
});

const {name:nameDiscover,slug:slugDiscover } = toRefs(brandDiscoverSelected);
const {name:nameDiscoverAll,slug:slugDiscoverAll } = toRefs(brandAllBrandsDiscoverSelected);

const handleDiscoverBrand= (slug:string) =>{
  slugDiscover.value= slug;
  nameDiscover.value= brandInfoStore.getBrandNameBySlug(slug);
};

const handleDiscoverBrandAll= (slug:string, brand: string) =>{
  slugDiscoverAll.value= slug;
  nameDiscoverAll.value= brand;
};

const setAllBrands = () => {
  slugDiscover.value= '';
  nameDiscover.value= '';
}

const allBrandsCars= activeBrandsFilter.value.map(({nombre_comercial,slug,img_vehiculo_default:thumbnail})=>({marca:nombre_comercial,slug,thumbnail}))

const gamas = ref([]);

/**
 * Establece las gamas
 */
const getGamas = async () => {

    const { data, error } = await $api.gamas.getGamas(slugDiscover.value,{
      pick:['payload']
    });
        
    if (error.value) {
      throw createError({ statusCode: 500, statusMessage: error.value?.data?.message || error.value.message })
    }
    gamas.value = await data.value?.payload as unknown;
    setGamas(gamas.value);

};

// Para gestionar la seleccion de la marca en el splide cuando esta seleccionada la opcion de todas las marcas
const onMove = (event: Event) => {
  const selectedBrand = allBrandsCars[event.index];
  handleDiscoverBrandAll(selectedBrand.slug, selectedBrand.marca)
};

watch(
  () => slugDiscover.value,
  async (newValue)=>{
    if(newValue!==''){
      await getGamas();
    }else{
      gamas.value = allBrandsCars;
      const selectedBrand = allBrandsCars[0];
      handleDiscoverBrandAll(selectedBrand.slug, selectedBrand.marca)
      if (splideRef.value) {
        splideRef.value.go(0);
      }
    }
},
{ immediate: true });

//Módulo Promociones marcas
const activeBrandsPromoVn= ref(activeBrandsFilter.value.filter(brand => brand.secciones_opcionales && brand.secciones_opcionales.promociones_vn));

const brandPromoSelected= reactive({
  name: '',
  slug: ''
});

const {name:namePromo,slug:slugPromo } = toRefs(brandPromoSelected);

const handlePromoBrand= (slug:string) =>{
  slugPromo.value= slug;
  namePromo.value= brandInfoStore.getBrandNameBySlug(slug);
};

//Establece marca por defecto 
handlePromoBrand(activeBrandsPromoVn.value[0]['slug'] ?? '');
 

const promos = ref([]);
const promosInfo = ref([]);


/**
 * Establece las promociones
 */

const getPromos = async () => {

  const formData = new FormData();
  formData.append('slug_marca', slugPromo.value);
  formData.append('category', 'vn'); 

  const {
      data: promosData,
      pending: promosPending,
      error:promosError
  } = await $api.promos.getPromos(formData,{
      pick: ['payload']
      }); 
  

  if (promosError.value) {
    throw createError({ statusCode: 500, statusMessage: promosError.value?.data?.message || promosError.value.message });
  }
  
  promos.value = promosData.value.payload.data;
  promosInfo.value = promosData.value.payload.metas;

  
};

watch(
  () => slugPromo.value,
  async ()=>{
    await getPromos();
  },
  { immediate: true }
);


onMounted(()=>{
  if(!popupsStore.popupPagesVisited.includes('main')){
    popupsStore.showPopup('','home'); // Carga el popup inicial
  }
});

</script>

<template lang="pug">
NuxtLayout
  .app-home
    article.container.container-big.app-home__info.section
      .row 
        .col-lg-7 
          .home-card.round-box.intro 
            img.img-fluid(src="~assets/images/header-module1_2.jpg", alt="")
            .home-card__content
              //- h2.font--primary Elijas el camino que elijas, en Marcos Automoción podrás encontrar a tu compañero ideal.
              .links.d-flex.justify-content-center
                ul
                  li
                    nuxt-link(to="/coches-nuevos/")
                      span.icon.icon-car
                      span.text Compra tu coche #[strong nuevo]
                  li(v-if="!$config.public.pmvMode")
                    nuxt-link(to="/coches-nuevos/")
                      span.icon.icon-moto
                      span.text Compra tu #[strong moto]
                  li
                    nuxt-link(to="https://www.marcosrenting.com/" target="_blank")
                      span.icon
                        img(src="/images/icons/marcos-renting.svg" alt="Logo Marcos Renting").svg-renting
                      span.text Marcos Renting
                  li
                    nuxt-link(to="/cita-previa-taller/")
                      span.icon.icon-note-text
                      span.text Pide tu #[strong cita de taller]
                  //- li
                  //-   nuxt-link.btn-tertiary(to="/movilidad/") Tu solución de movilidad completa
                  //- li
                  //-   nuxt-link.btn-tertiary(to="/cita-previa-taller/") Pide tu cita de taller

          .app-home__info-bottom.mt-4
            .home-banner.round-box.bg--tertiary.text--white 
              //- img.img-fluid(src="~assets/images/home-module-1-a.jpg", alt="")
              .home-banner__content
                .home-banner__inner.d-flex.align-items-center.w-100
                  .row.align-items-center.w-100.mx-auto
                      p.h2-headline Descubre las marcas pertenecientes a Marcos Automoción
                      ClientOnly
                        UiAppBrandsHome 

        .col-lg-5.app-home__external-site
          
          img(src="/images/logo-modrive.svg", class="logo-modrive" alt="MODRIVE")
          .modrive-ad-content
                p.h3-headline Descubre 
                  br
                  | nuestro stock 
                  br
                  | de ocasión
                a.btn-tertiary(href="https://www.modrive.com", target="_blank") Ir a MODRIVE
    article
      .container.container-big
        .row 
          .col-12.text-center.mb-5 
            img(src="~assets/images/marcos-automocion-logo-iso-color.png", alt="", width="122")
            h1.headline--1 Marcos Automoción
        .row
          .col-12.bg--neutral-40
            .d-lg-flex.justify-content-center.gap-4.pt-5.py-lg-5.text-center.text-lg-start
              h2.my-md-auto Descubre todas 
                br
                | nuestras marcas 

             
              .dropdown.my-lg-auto
                button.dropdown-toggle.text-uppercase(
                  id="dropdown-select-brand", 
                  type="button", 
                  data-bs-toggle="dropdown", 
                  aria-expanded="false") {{ !brandDiscoverSelected.name || !brandDiscoverSelected.slug ? 'TODAS LAS MARCAS' :  brandDiscoverSelected.name }} 
                ul.dropdown-menu(aria-labelledby="dropdown-select-brand")
                  li
                    .dropdown-item
                      input(type="radio", id="allBrands",name="brand-select",:checked="brandDiscoverSelected.slug === ''", @click="setAllBrands")
                      label(for="allBrands").w-100
                        .row.d-flex.align-items-center.w-100
                          .col-3.d-flex.justify-content-center
                              span 
                                  img(:src="`/images/marcos-icono-gray.svg`", alt="Marcos Automoción", class="marcos-icon-small")
                          .col-9.ps-0
                            span Todas las marcas
                  li(v-for="(item, index) in activeBrandsFilter" :key="index")
                    .dropdown-item
                      input(type="radio", :id="item.slug",name="brand-select",:checked="brandDiscoverSelected.slug === item.slug", @click="handleDiscoverBrand(item.slug)")
                      label(:for="item.slug").w-100
                        .row.d-flex.align-items-center.w-100
                          .col-3.d-flex.justify-content-center
                              span.icon(:class="`icon-${item.slug}`")
                          .col-9.ps-0
                            span {{ item.nombre_comercial }}
            .app-home__slider.brand-slider
              .brand-slider__top
                .container 
                  .row 
                    .col-12.p-0
                      .brand-slider__info.mx-2.mx-lg-4
                              h2.headline--2 {{ brandDiscoverSelected?.name!== '' ? brandDiscoverSelected.name: brandAllBrandsDiscoverSelected?.name }} Marcos Automoción
                              hr
                              ul.w-50.w-lg-100.d-flex.flex-lg-column.flex-wrap.gap-3.justify-content-center
                                li
                                  NuxtLink.btn-tertiary(:to="`/${brandDiscoverSelected?.slug!== '' ? brandDiscoverSelected.slug: brandAllBrandsDiscoverSelected?.slug}/gama/`") Ver gama {{brandDiscoverSelected?.name!== '' ? brandDiscoverSelected.name: brandAllBrandsDiscoverSelected?.name}}
                                li
                                  NuxtLink.btn-tertiary(:to="`/${brandDiscoverSelected?.slug!== '' ? brandDiscoverSelected.slug: brandAllBrandsDiscoverSelected?.slug}/coches-nuevos/`") Ver stock vehículo
                                li
                                  NuxtLink.btn-tertiary(:to="`/${brandDiscoverSelected?.slug!== '' ? brandDiscoverSelected.slug: brandAllBrandsDiscoverSelected?.slug}/instalaciones/`") Ver todas las sedes
                      .brand-slider__logo 
                        span(v-if="brandDiscoverSelected?.name!== ''",:class="[`icon-${brandDiscoverSelected?.slug!== '' ? brandDiscoverSelected.slug: 'marcos-automocion'}`]")
                        img(v-else, :src="`/images/marcos-icono-gray.svg`", alt="Marcos Automoción", class="marcos-icon")

              .brand-slider__slider 
                ClientOnly
                  splide(
                    ref="splideRef"
                    :options="options"
                    @splide:move="onMove"
                    @splide:mounted="onMove"
                  ) 
                    splide-slide(v-for="(gama, index) in gamas", :key="index")
                      .brand-slider__slider-item
                        NuxtLink(
                          v-if="!brandDiscoverSelected.name || brandDiscoverSelected.name==='' || !brandDiscoverSelected.slug || brandDiscoverSelected.slug===''"
                          :to="`/${gama.slug}/gama/`"
                        )
                          img.img-fluid(:src="gama.thumbnail" :alt="gama.marca")
                        NuxtLink(v-else :to="{path: `/${brandInfoStore.getBrandSlugByNoComercialName(gama.marca)}/gama/${gama.slug_modelo}/`, query: {type: gama.tipo_vehiculo}}")
                          img.img-fluid(:src="gama.thumbnail" :alt="`${gama.marca} ${gama.modelo}`")
    article.container-fluid.app-home__cycles.mt-5(v-if="isMarcosCyclesActive")
      .row 
        .col-12.p-0 
          .grid--18.grid--rows-12
            .grid-child--1-end.grid-child--row-2-end.grid-child-md--row-1-end
              img.img-fluid.d-none.d-md-inline-block(src="~assets/images/home-moto.jpg", alt="")
              img.img-fluid.d-md-none(src="~assets/images/home-moto-mobile.png", alt="")
            .grid-child--9-17.grid-child--row-1-end.grid-child-md--12-16.grid-child-md--row-1-10.grid-child--self-start.bg--tertiary.text--white
              .app-home__cycles-content
                div
                  h2.headline--2 Marcos Cycles
                  p.lead Un nuevo concepto en movilidad. Descubre nuestro mejor stock en motos.
                nuxt-link.btn-tertiary.btn-tertiary--white(to="/marcos-cycles/") Ir a Marcos Cycles
                
    article.container-fluid.app-home__promotions.mt-5.pb-0(v-if="false")
      .row 
        .col-12
          .grid--18.grid-md--rows-12
            .grid-child--1-12.grid-child-lg--3-7.grid-child-lg--row-1-13.bg--tertiary.text--white
              .app-home__cycles-content 
                div
                  h2.headline--2 Promociones vehículos nuevos
                  .dropdown
                    button.dropdown-toggle.dropdown-toggle-promos.text-uppercase(
                    id="dropdown-select-promo-brand", 
                    type="button", 
                    data-bs-toggle="dropdown", 
                    aria-expanded="false") {{ !brandPromoSelected.name || !brandPromoSelected.name ? 'SELECCIONA UNA MARCA' :  brandPromoSelected.name }} 
                    ul.dropdown-menu(aria-labelledby="dropdown-select-promo-brand")
                      li(v-for="(item, index) in activeBrandsPromoVn" :key="index")
                        .dropdown-item
                          input(type="radio", :id="`promo-${item.slug}`", name="brand-select-promo",:checked="brandPromoSelected.slug === item.slug", @click="handlePromoBrand(item.slug)")
                          label(:for="`promo-${item.slug}`").w-100
                            .row.d-flex.align-items-center.w-100
                              .col-6.d-flex.justify-content-center
                                span.icon(:class="`icon-${item.slug}`")
                              .col-6.ps-0
                                span {{ item.nombre_comercial }}
                                
                nuxt-link.btn-tertiary.btn-tertiary--white(:to="`/${brandPromoSelected.slug}/promociones-marcas/`") Ver todas las promociones
            .grid-child--1-end.grid-child-lg--8-end.grid-child-xxl--8-18.grid-child-md--row-2-end
              .container-fluid 
                .row(v-if="promos.length > 0")
                  .col-12.col-lg-6.mb-4(v-for="(item, index) in promos.slice(0,2)" :key="index")
                    nuxt-link(:to=" item.enlace_externo ? item.url_externa : `/${brandPromoSelected.slug}/promociones-marcas/${item.slug}`" :target="item.enlace_externo ? '_blank' : '_self'")
                      UiAppCardRegular(
                      :img="item.thumbnail",
                      :titulo="item.titulo",
                      :resumen="item.resumen"
                      )
                        template(#footer)
                          .text-center 
                            nuxt-link.btn-tertiary(:to=" item.enlace_externo ? item.url_externa : `/${brandPromoSelected.slug}/promociones-marcas/${item.slug}`" :target="item.enlace_externo ? '_blank' : '_self'") Más información
                .row.col-12.text-center.py-5(v-else)
                  .no-results-promos.my-5
                    .container
                      .d-flex.flex-column.flex-lg-row.col-12.mx-auto.align-items-center
                        .col-6
                          img.img-fluid(src="/images/no-promos.png")
                        .col-6.mt-5.mt-lg-0
                          p.no-results-promos__title No hay promociones disponibles.
                          p.no-results-promos__subtitle Elige otra marca para ver otras opciones.


              //- .d-flex.flex-column.flex-lg-row.gap-4.gap-lg-5
                .col-12.col-lg-6.px-0.mb-4(v-for="(item, index) in promos.slice(0,2)" :key="index")
                  nuxt-link(:to="`/${brandPromoSelected.slug}/promociones-marcas/${item.slug}`")
                    UiAppCardRegular(
                    :img="item.thumbnail",
                    :titulo="item.titulo",
                    :resumen="item.resumen"
                    )
                      template(#footer)
                        .text-center 
                          nuxt-link.btn-tertiary(:to="`/${brandPromoSelected.slug}/promociones-marcas/${item.slug}`") Más información

    UiAppModuleVehicleSearch        

    article.section.bg--neutral-40.app-home__services.mt-5.mt-lg-0
      .container 
        .row 
          .col-12.pe-5
            h2.headline--2 Amplia oferta de servicios en Marcos Automoción
      .container.mt-3.mt-lg-5
        .row 
          .col-md-4.mb-4.mb-lg-0 
            .home-card.scale-hover.round-box
              img.img-fluid(src="~assets/images/home-services-promotions.jpg", alt="")
              .home-card__content.bottom-left.text--white 
                .d-lg-flex.justify-content-between.gap-3 
                  nuxt-link.btn-tertiary(to="/cita-previa-taller/") Cita previa
                  nuxt-link.btn-tertiary(to="/centros-chapa-y-pintura/") Chapa y pintura
          .col-md-4.mb-4.mb-lg-0 
            .home-card.scale-hover.round-box
              img.img-fluid(src="/images/home-services-companies.jpg", alt="")
              .home-card__content.bottom-left.text--white
                .home-card__inner 
                  p.h3-headline Empresas
                  nuxt-link.btn-tertiary(to="/servicios-empresas/") Más información
          .col-md-4.mb-4.mb-lg-0 
            .home-card.scale-hover.round-box.video-container
              video.obj-cover(width='320', height='240', poster="/images/home-electricos-poster.jpg", autoplay, muted, loop,playsinline)
                source(src='/videos/video-seguros.mp4', type='video/mp4')
              .home-card__content.bottom-left.text--white 
                .home-card__inner 
                  p.h3-headline Seguros
                  nuxt-link.btn-tertiary(to="/seguros/") Más información


    UiAppModuleNewsHighlights.mb-5

    UiAppModuleSeo(slug="index")

</template>

<style lang="scss">
@import 'index';
</style>
